import { cpf } from 'cpf-cnpj-validator';

export function checkUsername(username) {
  var words = ["gmail", "hotmail", "yahoo"];
  var check = true;

  words.map(word => {
    if (username.match(word)) {
      check = false;
    }
  });

  return check;
}

export function checkCpf(taxid = '') {
  var check = true;

  if (taxid) {
    if (!cpf.isValid(taxid)) {
      check = false;
    }
  }

  return check;
}

export function checkAge(dateOfBirth) {
  if (!dateOfBirth) {
    return true
  }

  // Split the date into day, month, and year
  const parts = dateOfBirth.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months start at 0 (January = 0)
  const year = parseInt(parts[2], 10);

  // Create a Date object with the date of birth
  const dateOfBirthObj = new Date(year, month, day);

  // Calculate the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds between the current date and date of birth
  const differenceInMilliseconds = currentDate - dateOfBirthObj;

  // Convert the difference in milliseconds to years
  const years = differenceInMilliseconds / (365 * 24 * 60 * 60 * 1000);

  // Check if the person is over 16 years old
  if (years >= 16) {
    return true;
  } else {
    return false;
  }
}